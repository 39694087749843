const changelogData = [
  {
    version: 'v1.0Beta054502192025',
    date: 'February 19th, 2025',
    changes: [
      'Added Dropdown for KG and LBS selection for International users',
      'Added Dropdown for flight type (business (no children)) ',
      'Fixed typo issues for the entire website',
      'Fixed a total capacity error for the 737-800MAX',
      'Added a Simbrief Connection button (coming soon)',
      'Fixed CSS inline issues on a rogue html page',
    ],
  },
  {
    version: 'v1.0Beta094802012025',
    date: 'February 1st, 2025',
    changes: [
      'New way of directing to privacy policy',
    ],
  },
  {
    version: 'v1.0Beta081902012025',
    date: 'February 1st, 2025',
    changes: [
      'Removed needed to sign up to use the website',
      'Fixed eccessive child counts to a more realstic data (added 2023 and 2024 data)',
      'PMDG ZFW only shows on PMDG planes',
      'Updated manifest.json',
      'Fixed the Privacy Policy',
      
    ],
  },
  {
    version: 'v1.0Beta13011142024',
    date: 'November 4th, 2024',
    changes: [
      'Added Fenix Airbus series',
      'Added Discord to Contact us page',
      'Added skeleton Account Page',
      'Fixed typos',
      'Plane selection with dev together',
      'Database optimizations and additions',
      'New Secuiruty measures',
    ],
  },
  {
    version: 'v1.0Beta07171132024',
    date: 'November 3rd, 2024',
    changes: [
      'Changed FAA weights to Winter Weights',
      'Added google Adsense to the website',
    ],
  },
  {
    version: 'v1.0Beta152610222024',
    date: 'October 22, 2024',
    changes: [
      'Added Privacy Policy',
      'Started google Adsense',
      'Started Account page for users',
    ],
  },
  {
    version: 'v1.0Beta080610222024',
    date: 'October 22, 2024',
    changes: [
      'Added PAX distribution for the IFly 737-800MAX',
    ],
  },
  {
    version: 'v1.0Beta033810182024',
    date: 'October 18, 2024',
    changes: [
      'Changed Adult and Children Weights to correctly match the FAA standards',
      'Added Contact Us Page',
      'Added Sumbit Request Page',
      'Added Requests email',
    ],
  },
  {
    version: 'v1.0Beta045010152024',
    date: 'October 15, 2024',
    changes: [
      'Correctly Added the IFLY 737-800MAX to the database',
    ],
  },
  {
    version: 'v1.0Beta1948102024',
    date: 'October 10, 2024',
    changes: [
      'Added PMDG 777-300ER to the database',
      'Minor refactoring of the calculation algorithm',
      'Minor text changes',
      'Added Icons to guide for simbrief',
      'Updated Reacts most update to date modules',
    ],
  },
  {
    version: 'v1.0Beta034110102024',
    date: 'October 10, 2024',
    changes: [
      'Fixed an issue where in some cases the loading % was not displaying correctly',
    ],
  },
  {
    version: 'v1.0Beta033110102024',
    date: 'October 10, 2024',
    changes: [
      'Fixed spelling errors in the disclaimer file',
      'Made frieght an optional field',
      'Added baseline for google 0Auth',
      'Added baseline for JWST',
      'Made adjustments for performance and stability',
    ],
  },
    {
        version: 'v1.0Beta173210092024',
        date: 'October 9, 2024',
        changes: [
          'Added SSO',
          'Database connection + security added',
          'Added Minor CSS changes',
        ],
      },
    {
        version: 'v1.0Beta145010092024',
        date: 'October 9, 2024',
        changes: [
          'Refactor of Baggage calculations',
          'Added Temp favicon and logos',
          'Added Minor CSS changes',
        ],
      },
    {
        version: 'v1.0Beta140710092024',
        date: 'October 9, 2024',
        changes: [
          'Changelog CSS changes for UI',
          'Disclaimer file changes',
        ],
      },
    {
      version: 'v1.0Beta140010092024',
      date: 'October 9, 2024',
      changes: [
        'Completed Overhaul',
        'Improved user interface for the login modal.',
        'Fixed bugs in the payload calculation algorithm.',
        'Added Changelog page.',
        'Major Secuirty Changes',
      ],
    },
    {
      version: 'v1.0Beta133410092024',
      date: 'October 9, 2024',
      changes: [
        'Launched the new Simpayloads website.',
        'Introduced account creation and login functionality.',
        'Implemented responsive design for mobile devices.',
      ],
    },
    // Add more entries as needed
  ];

  export default changelogData;