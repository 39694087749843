// Header.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { AuthContext } from './AuthContext';

function Header({ onLogin }) {
  const { isAuthenticated, handleLogout } = useContext(AuthContext);

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">
        Simpayloads.com
      </Link>
      <div className="navbar-links">
        <Link to="/changelog" className="navbar-link">
          Changelog
        </Link>
        <Link to="/contact-us" className="navbar-link">
          Contact Us
        </Link>
        <Link to="/submit-request" className="navbar-link">
          Submit Request
        </Link>
        {/* New disabled Connect SimBrief button */}
        <span className="navbar-link disabled-link" title="Coming Soon">
          Connect SimBrief
        </span>
        {isAuthenticated ? (
          <>
            <Link to="/account" className="navbar-link">
              My Account
            </Link>
            <button onClick={handleLogout} className="navbar-link logout-button">
              Logout
            </button>
          </>
        ) : (
          <button onClick={onLogin} className="navbar-link login-button">
            Login
          </button>
        )}
      </div>
    </nav>
  );
}

export default Header;
