// BaggageEstimator.js
import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { FaQuestionCircle } from 'react-icons/fa'; // Font Awesome Question Circle Icon

// Import react-tooltip styles
import 'react-tooltip/dist/react-tooltip.css';

// Constants (all defined in imperial units)
const HEAVY_BAG_WEIGHT = 51; // lbs
const NORMAL_BAG_WEIGHT = 32; // lbs
const AVG_ADULT_WEIGHT = 208; // lbs
const AVG_CHILD_WEIGHT = 92; // lbs

// Conversion factors
const LBS_TO_KG = 0.453592;
const KG_TO_LBS = 1 / LBS_TO_KG; // ~2.20462

// Plane Data (as before)
const planeData = {
  '737-700': {
    BEW: 83000,
    emptyCGArm: -6.171,
    maxFWDWeight: 4200,
    maxAFTWeight: 7000,
    PMDGZFWCorrection: 1693,
    maxPassengers: 143,
    calculateBins: (totalBags) => {
      const binD = Math.ceil(totalBags * 0.6);
      const binC = totalBags - binD;
      return { binC, binD };
    },
  },
  '737-800': {
    BEW: 91300,
    emptyCGArm: -6.171,
    maxFWDWeight: 7500,
    maxAFTWeight: 10300,
    PMDGZFWCorrection: 1693,
    maxPassengers: 189,
    calculateBins: (totalBags) => {
      const binC =
        totalBags <= 120
          ? totalBags
          : Math.floor(Math.random() * 21) + 100; // Random between 100 and 120
      const binD = totalBags - binC;
      return { binC, binD };
    },
  },
  '737-MAX8': {
    BEW: 99360,
    emptyCGArm: -6.171,
    maxFWDWeight: 7600,
    maxAFTWeight: 10500,
    PMDGZFWCorrection: 0,
    maxPassengers: 177,
    passengerZones: {
      A: { capacity: 12, arm: 17.91 },
      B: { capacity: 30, arm: 6.10 },
      C: { capacity: 36, arm: -6.28 },
      D: { capacity: 28, arm: -11.00 },
      E: { capacity: 24, arm: -16.43 },
      F: { capacity: 23, arm: -22.48 },
      G: { capacity: 24, arm: -28.05 },
    },
    calculateBins: (totalBags) => {
      const binD = Math.ceil(totalBags * 0.7);
      const binC = totalBags - binD;
      return { binC, binD };
    },
  },
  '777-300ER': {
    BEW: 326000,
    emptyCGArm: -6.171,
    maxFWDWeight: 41206,
    maxAFTWeight: 35095,
    PMDGZFWCorrection: 0,
    maxPassengers: 370,
    calculateBins: (totalBags) => {
      const binC = Math.ceil(totalBags * 0.6);
      const binD = totalBags - binC;
      return { binC, binD };
    },
  },
  'A319': {
    BEW: 89411,
    emptyCGArm: -6.171,
    maxFWDWeight: 2880,
    maxAFTWeight: 6170,
    PMDGZFWCorrection: 0,
    maxPassengers: 150,
    calculateBins: (totalBags) => {
      const binD = Math.ceil(totalBags * 0.7);
      const binC = totalBags - binD;
      return { binC, binD };
    },
  },
  'A320': {
    BEW: 94137,
    emptyCGArm: -6.171,
    maxFWDWeight: 6170,
    maxAFTWeight: 6170,
    PMDGZFWCorrection: 0,
    maxPassengers: 180,
    calculateBins: (totalBags) => {
      const binD = Math.ceil(totalBags * 0.7);
      const binC = totalBags - binD;
      return { binC, binD };
    },
  },
  'A321': {
    BEW: 105932,
    emptyCGArm: -6.171,
    maxFWDWeight: 12500,
    maxAFTWeight: 12988,
    PMDGZFWCorrection: 0,
    maxPassengers: 230,
    calculateBins: (totalBags) => {
      const binD = Math.ceil(totalBags * 0.7);
      const binC = totalBags - binD;
      return { binC, binD };
    },
  },
};

// Function to calculate total bags based on passenger count
const getTotalBags = (paxCountNum) => {
  if (paxCountNum <= 5) {
    return Math.floor(Math.random() * 6);
  } else if (paxCountNum <= 10) {
    return Math.floor(Math.random() * 11);
  } else if (paxCountNum <= 15) {
    return Math.floor(Math.random() * 11);
  } else if (paxCountNum <= 30) {
    return Math.floor(Math.random() * 26);
  } else if (paxCountNum <= 50) {
    return Math.floor(Math.random() * 41);
  } else if (paxCountNum <= 75) {
    return Math.floor(Math.random() * 66) + 15;
  } else if (paxCountNum <= 143) {
    return Math.floor(Math.random() * 91) + 50;
  } else if (paxCountNum <= 189) {
    return Math.floor(Math.random() * 111) + 100;
  } else {
    return Math.floor(Math.random() * 301) + 150;
  }
};

// Tooltip content
const tooltipContent = {
  adultPaxCount: "Copy and paste this into Simbrief's PAX section.",
  totalPayload: "Copy and paste this into Simbrief's Payload section.",
};

const getChildPaxCount = (totalPassengers) => {
  const roll = Math.random();
  let childCount;
  if (roll < 0.75) {
    childCount = Math.floor(Math.random() * 13);
  } else if (roll < 0.95) {
    childCount = Math.floor(Math.random() * (18 - 5 + 1)) + 5;
  } else {
    childCount = Math.floor(Math.random() * (30 - 15 + 1)) + 15;
  }
  return Math.min(childCount, totalPassengers);
};

function BaggageEstimator() {
  // Existing states
  const [planeType, setPlaneType] = useState('');
  const [passengerCount, setPassengerCount] = useState('');
  const [freightWeight, setFreightWeight] = useState('');
  const [results, setResults] = useState({});
  const [buttonText, setButtonText] = useState('Estimate Baggage');
  const [simBriefResults, setSimBriefResults] = useState({});
  const [resultsLoading, setResultsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [maxPassengerCount, setMaxPassengerCount] = useState(null);
  const [passengerDistribution, setPassengerDistribution] = useState(null);
  const [flightType, setFlightType] = useState('leisure'); // default is leisure

  
  // New state for unit conversion
  // "lbs" for Imperial and "kg" for Metric
  const [unit, setUnit] = useState(localStorage.getItem('unit') || 'lbs');

  // Save unit selection to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('unit', unit);
  }, [unit]);
  useEffect(() => {
    localStorage.setItem('flightType', flightType);
  }, [flightType]);

  // Update maxPassengerCount when planeType changes
  useEffect(() => {
    if (planeType && planeData[planeType]) {
      setMaxPassengerCount(planeData[planeType].maxPassengers);
    } else {
      setMaxPassengerCount(null);
    }
    setPassengerCount('');
    setPassengerDistribution(null);
  }, [planeType]);

  const distributePassengersToZones = (totalPassengers, zones) => {
    // Removed totalCapacity, since it wasn't used
    let totalWeightedCapacity = 0;
  
    // Calculate total weighted capacity
    for (const zone of Object.values(zones)) {
      totalWeightedCapacity += zone.capacity / Math.abs(zone.arm);
    }
  
    const initialAllocation = {};
    let allocatedPassengers = 0;
  
    // Initial allocation based on weighted proportions
    for (const [zoneName, zone] of Object.entries(zones)) {
      const weight = (zone.capacity / Math.abs(zone.arm)) / totalWeightedCapacity;
      const passengersInZone = Math.floor(totalPassengers * weight);
      const adjustedPassengers = Math.min(passengersInZone, zone.capacity);
      initialAllocation[zoneName] = adjustedPassengers;
      allocatedPassengers += adjustedPassengers;
    }
  
    // Distribute remaining passengers starting from the center
    let remainingPassengers = totalPassengers - allocatedPassengers;
    const sortedZones = Object.entries(zones).sort(
      (a, b) => Math.abs(a[1].arm) - Math.abs(b[1].arm)
    );
  
    for (const [zoneName, zone] of sortedZones) {
      if (remainingPassengers === 0) break;
      const availableCapacity = zone.capacity - initialAllocation[zoneName];
      if (availableCapacity > 0) {
        const passengersToAdd = Math.min(availableCapacity, remainingPassengers);
        initialAllocation[zoneName] += passengersToAdd;
        remainingPassengers -= passengersToAdd;
      }
    }
  
    return initialAllocation;
  };
  

  // Helper: Format weight for display based on unit selection
  const formatWeight = (weightInLbs) => {
    return unit === 'lbs'
      ? `${Math.round(weightInLbs)} lbs`
      : `${(weightInLbs * LBS_TO_KG).toFixed(2)} kg`;
  };

  const calculateBaggage = (event) => {
    event.preventDefault();
    if (!planeType) {
      alert('Please select a plane type.');
      return;
    }
    const plane = planeData[planeType];
    const paxCountNum = parseInt(passengerCount, 10);
    if (isNaN(paxCountNum) || paxCountNum < 0) {
      alert('Please enter a valid passenger count.');
      return;
    }
    if (paxCountNum > plane.maxPassengers) {
      alert(`The maximum number of passengers for ${planeType} is ${plane.maxPassengers}.`);
      return;
    }
  
    const totalBags = getTotalBags(paxCountNum);
    const { binC, binD } = plane.calculateBins(totalBags);
    const fwdWeight = Math.round(
      binC * NORMAL_BAG_WEIGHT + binC * 0.1 * HEAVY_BAG_WEIGHT
    );
    const aftWeight = Math.round(
      binD * NORMAL_BAG_WEIGHT +
      binD * 0.1 * HEAVY_BAG_WEIGHT +
      (unit === 'lbs'
        ? parseFloat(freightWeight || 0)
        : parseFloat(freightWeight || 0) * KG_TO_LBS)
    );
    
    // If the flight type is "business", force zero children.
    const childPaxCount = flightType === 'business'
      ? 0
      : getChildPaxCount(paxCountNum);
    const adultPaxCount = paxCountNum - childPaxCount;
    
    const totalPaxWeight = Math.round(
      adultPaxCount * AVG_ADULT_WEIGHT + childPaxCount * AVG_CHILD_WEIGHT
    );
    const totalBinWeights = fwdWeight + aftWeight;
    const totalPayload = Math.round(totalPaxWeight + totalBinWeights);
    const isPMDG = planeType === '737-700' ||
                   planeType === '737-800' ||
                   planeType === '777-300ER';
    const ZFW = isPMDG ? plane.BEW + totalPayload + plane.PMDGZFWCorrection : null;
  

    // Function to calculate SimBrief results
    const calculateSimBriefResults = () => {
      const totalAdultPassengerWeight = adultPaxCount * AVG_ADULT_WEIGHT;
      const totalNormalBagWeight = (binC + binD) * NORMAL_BAG_WEIGHT;
      const totalBinWeightsExcludingHeavy = Math.round(
        totalBinWeights -
          binC * 0.1 * HEAVY_BAG_WEIGHT -
          binD * 0.1 * HEAVY_BAG_WEIGHT
      );
      const totalPayloadSimBrief =
        totalAdultPassengerWeight +
        totalNormalBagWeight +
        (unit === 'lbs'
          ? parseFloat(freightWeight || 0)
          : parseFloat(freightWeight || 0) * KG_TO_LBS);
      setSimBriefResults({
        totalPax: adultPaxCount,
        totalPayload: totalPayloadSimBrief,
        totalBinWeightsExcludingHeavy: totalBinWeightsExcludingHeavy,
      });
    };

    if (planeType === '737-MAX8' && plane.passengerZones) {
      const distribution = distributePassengersToZones(paxCountNum, plane.passengerZones);
      setPassengerDistribution(distribution);
    } else {
      setPassengerDistribution(null);
    }

    // Simulate loading with a 2-second delay
    setResultsLoading(true);
  setButtonText('Estimating...');
  setProgress(0);
  setTimeout(() => {
    setButtonText('Estimate Baggage');
    setResults({
      adultPaxCount: adultPaxCount,
      childPaxCount: childPaxCount,
      totalBags: totalBags,
      fwdWeight: fwdWeight,
      aftWeight: aftWeight,
      totalBinWeights: totalBinWeights,
      totalPayload: totalPayload,
      ZFW: ZFW,
    });
    calculateSimBriefResults();
    setResultsLoading(false);
  }, 2000);
};

  // Progress bar effect
  useEffect(() => {
    let timer = null;
    if (resultsLoading) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          const increment = Math.random() > 0.3 ? Math.floor(Math.random() * 2) + 1 : 0;
          const newProgress = oldProgress + increment;
          return newProgress < 95 ? newProgress : 95;
        });
      }, 100);
    } else {
      setProgress(100);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [resultsLoading]);

  return (
    <div className="container">
      <h2>Payload Estimator</h2>
      
      <div
  style={{
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: '1rem',
    rowGap: '1rem',
    justifyContent: 'center', // center the grid as a whole
    marginBottom: '20px',
  }}
>
  <label htmlFor="unit-select" style={{ textAlign: 'right', fontWeight: '500' }}>
    Units:
  </label>
  <select
    id="unit-select"
    className="tech-dropdown"
    value={unit}
    onChange={(e) => setUnit(e.target.value)}
  >
    <option value="lbs">Imperial (lbs)</option>
    <option value="kg">Metric (kg)</option>
  </select>

  <label htmlFor="flightType" style={{ textAlign: 'right', fontWeight: '500' }}>
    Flight Type:
  </label>
  <select
    id="flightType"
    className="tech-dropdown"
    value={flightType}
    onChange={(e) => setFlightType(e.target.value)}
  >
    <option value="leisure">Leisure</option>
    <option value="business">Business</option>
  </select>
</div>
      
      <div className="form-container">
        <form onSubmit={calculateBaggage}>
          {/* Plane Type */}
          <label htmlFor="planeType">Plane Type</label>
          <select
            id="planeType"
            value={planeType}
            onChange={(e) => setPlaneType(e.target.value)}
            required
          >
            <option value="">Select a plane</option>
            <option value="737-700">PMDG 737-700</option>
            <option value="737-800">PMDG 737-800</option>
            <option value="777-300ER">PMDG 777-300ER</option>
            <option value="737-MAX8">IFLY 737-MAX8</option>
            <option value="A319">Fenix A319-100</option>
            <option value="A320">Fenix A320-200</option>
            <option value="A321">Fenix A321-200</option>
          </select>

          {/* Passenger Count */}
          <label htmlFor="passengerCount">Passenger Count</label>
          <input
            type="number"
            id="passengerCount"
            value={passengerCount}
            onChange={(e) => setPassengerCount(e.target.value)}
            placeholder="Enter number of passengers"
            min="0"
            max={maxPassengerCount || ''}
            disabled={!planeType}
            required
          />

          {/* Freight Weight */}
          <label htmlFor="freightWeight">
            Freight Weight ({unit === 'lbs' ? 'lbs' : 'kg'}) (optional)
          </label>
          <input
            type="number"
            id="freightWeight"
            value={freightWeight}
            onChange={(e) => setFreightWeight(e.target.value)}
            placeholder="Enter freight weight"
            min="0"
            disabled={!planeType}
          />

          <button type="submit" className="btn" disabled={!planeType}>
            {buttonText}
          </button>
        </form>
      </div>

      {/* Results Display */}
      <div className="results-container">
        {resultsLoading ? (
          <div className="result-card">
            <div className="loader-container">
              <div className="loader"></div>
              <div className="progress-text">{progress}%</div>
            </div>
          </div>
        ) : results.totalPayload ? (
          <div className="result-card">
            <h3>Actual Estimation Results</h3>
            <ul>
              <li>Total Adult Passengers: {results.adultPaxCount}</li>
              <li>Total Child Passengers: {results.childPaxCount}</li>
              <li>Number of Bags: {results.totalBags}</li>
              <li>FWD Bins Weight: {formatWeight(results.fwdWeight)}</li>
              <li>AFT Bins Weight: {formatWeight(results.aftWeight)}</li>
              <li>Total Bin Weights: {formatWeight(results.totalBinWeights)}</li>
              <li>Total Payload: {formatWeight(results.totalPayload)}</li>
              {results.ZFW !== null && (
                <li>(PMDG ONLY) Zero Fuel Weight: {formatWeight(results.ZFW)}</li>
              )}
            </ul>
          </div>
        ) : null}

        {resultsLoading ? (
          <div className="result-card">
            <div className="loader-container">
              <div className="loader"></div>
              <div className="progress-text">{progress}%</div>
            </div>
          </div>
        ) : simBriefResults.totalPayload ? (
          <div className="result-card">
            <h3>SimBrief Data Input</h3>
            <p className="note">
              * This excludes child weights and heavy bags from the payload.
            </p>
            <ul>
              <li>
                Total Adult Passengers: {simBriefResults.totalPax}
                <FaQuestionCircle
                  data-tooltip-id="simTotalPaxTip"
                  data-tooltip-content={tooltipContent.adultPaxCount}
                  className="question-icon"
                />
                <Tooltip id="simTotalPaxTip" place="right" />
              </li>
              <li>
                Total Bin Weights: {formatWeight(simBriefResults.totalBinWeightsExcludingHeavy)}
              </li>
              <li>
                Total Payload (without child weights and heavy bags): {formatWeight(simBriefResults.totalPayload)}
                <FaQuestionCircle
                  data-tooltip-id="simTotalPayloadTip"
                  data-tooltip-content={tooltipContent.totalPayload}
                  className="question-icon"
                />
                <Tooltip id="simTotalPayloadTip" place="right" />
              </li>
              {results.ZFW !== null && (
                <li>(PMDG ONLY) Zero Fuel Weight: {formatWeight(results.ZFW)}</li>
              )}
            </ul>
          </div>
        ) : null}

        {planeType === '737-MAX8' && (
          resultsLoading ? (
            <div className="result-card">
              <div className="loader-container">
                <div className="loader"></div>
                <div className="progress-text">{progress}%</div>
              </div>
            </div>
          ) : passengerDistribution ? (
            <div className="result-card">
              <h3>Passenger Distribution into Zones</h3>
              <table className="zone-table">
                <thead>
                  <tr>
                    <th>Zone</th>
                    <th>Capacity</th>
                    <th>Distributed Passengers</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(planeData[planeType].passengerZones).map(
                    ([zoneName, zoneData]) => (
                      <tr key={zoneName}>
                        <td>{zoneName}</td>
                        <td>{zoneData.capacity}</td>
                        <td>{passengerDistribution[zoneName] || 0}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <p>
                <strong>Total Assigned Passengers:</strong>{' '}
                {Object.values(passengerDistribution).reduce(
                  (sum, val) => sum + val,
                  0
                )}
              </p>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
}

export default BaggageEstimator;
