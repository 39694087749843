// PrivacyPolicy.js
import React, { useEffect, useState } from 'react';

function PrivacyPolicy() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    // Use a relative URL so the file is fetched from the same origin
    fetch('/privacyContent.html')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.text();
      })
      .then((text) => {
        setHtmlContent(text);
      })
      .catch((error) => {
        console.error('Fetch error:', error);
      });
  }, []);

  return (
    <div
      className="container"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
}

export default PrivacyPolicy;
