// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './Header';
import BaggageEstimator from './BaggageEstimator';
import Disclaimer from './Disclaimer';
import Changelog from './Changelog';
import ContactUs from './ContactUs';
import SubmitRequest from './SubmitRequest';
import PrivacyPolicy from './PrivacyPolicy';
import AccountPage from './AccountPage';
import ProfilePage from './ProfilePage';
import SettingsPage from './SettingsPage';
import PurchaseHistoryPage from './PurchaseHistoryPage';
// import AdminCenterPage from './AdminCenterPage'; // For future admin center
import './styles.css';
import { AuthProvider } from './AuthContext'; // Import AuthProvider

function App() {
  return (
    <AuthProvider>
      <Router>
        <MainApp />
      </Router>
    </AuthProvider>
  );
}

function MainApp() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<BaggageEstimator />} />
        <Route path="/changelog" element={<Changelog />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/submit-request" element={<SubmitRequest />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Account Routes (no authentication required) */}
        <Route path="/account/*" element={<AccountPage />}>
          <Route path="profile" element={<ProfilePage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="purchase-history" element={<PurchaseHistoryPage />} />
          {/* Default route under /account */}
          <Route path="" element={<Navigate to="profile" />} />
        </Route>

        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Disclaimer />
      <footer className="footer">
        <p>
          © Simpayloads.com. All Rights Reserved.
          <a href="/privacy-policy" className="privacy-link">
            Privacy Policy
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;
